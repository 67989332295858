import { addMemberRole, deleteMemberRole } from 'api/roles.js';
import { deleteMember, getHealthSystemMembers, getMembers, resetTwoFactorAuth } from 'api/users.js';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import PinGenerate from 'components/Users/PinGenerate.jsx';
import { Alert, Badge, Button, Form, Input, Modal, MultiSelectCheckboxes } from 'components/index.js';
import { WhiteboardSettings } from 'constants/configurationEnums.js';
import { AuthenticationOptions, CompanyRoles, InviteTypes, MembersType, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AddMemberRole from 'containers/UserManagement/Lists/AddMemberRole.jsx';
import ManageHealthSystems from 'containers/UserManagement/Lists/EditUser/ManageHealthSystems.jsx';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import {
	decodeHtml,
	getConfigurationValue,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
} from 'infrastructure/helpers/commonHelpers.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TablePinButton from 'icons/Admin/TablePinButton.jsx';
import TableAddButton from 'icons/Admin/TableAddButton.jsx';
import TableTwoFactorAuthButton from 'icons/Admin/TableTwoFactorAuthButton.jsx';

const UsersList = props => {
	const intl = useIntl();
	const companyId = getCompanyId();
	const userRole = getUserRole();
	const companySettings = useSelector(state => state.company.companySettings);
	const userSession = useSelector(state => state.user.userSession);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const maxSelectItems = 5;
	const delayTimerRef = useRef(null);

	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [totalCount, setTotalCount] = useState(0);
	const [members, setMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [currentMemberObj, setCurrentMemberObj] = useState(null);
	const [filterValues, setFilterValues] = useState({
		searchValue: '',
		roles: [],
		healthSystems: [],
	});
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
	const [pinGenerateMember, setPinGenerateMember] = useState(null);
	const [error, setError] = useState(null);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
	const [roleToDelete, setRoleToDelete] = useState(null);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [isInviteUsersDropDown, setIsInviteUsersDropDown] = useState(false);
	const [isTwoFaResetModalOpen, setIsTwoFaResetModalOpen] = useState(false);
	const [resetTwoFaModalData, setResetTwoFaModalData] = useState(null);
	const usersTableHeaders = [
		{ title: intl.formatMessage({ id: 'userName' }), id: 'profileBox' },
		{ title: intl.formatMessage({ id: 'email' }), id: 'email', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'invitedBy' }), id: 'invitedBy' },
		{ title: intl.formatMessage({ id: 'dateInvited' }), id: 'dateInvited' },
		{ title: intl.formatMessage({ id: 'dateAdded' }), id: 'dateAdded' },
		getUserRole() !== UserRoles.SUPER_ADMIN && {
			title: intl.formatMessage({ id: 'healthSystems' }),
			id: 'healthSystem',
			columnClass: 'normal-wrap',
		},
		{ title: intl.formatMessage({ id: 'status' }), id: 'status' },
		getUserRole() !== UserRoles.SUPER_ADMIN && {
			title: intl.formatMessage({ id: 'role' }),
			id: 'role',
			columnClass: 'normal-wrap',
		},
		{ title: '', id: 'edit' },
	].filter(Boolean);

	const rolesOptions = [
		{ value: CompanyRoles.OWNER, label: intl.formatMessage({ id: 'owner' }) },
		{ value: CompanyRoles.ADMIN, label: intl.formatMessage({ id: 'admin' }) },
		{ value: CompanyRoles.VIRTUAL_CARE_PROVIDER, label: companySettings.nurseDisplayName },
		{ value: CompanyRoles.VIRTUAL_SITTER, label: companySettings.virtualSitterDisplayName },
		{ value: CompanyRoles.SUPER_USER, label: intl.formatMessage({ id: 'superUser' }) },
		{ value: CompanyRoles.DIGITAL_CLINICIAN, label: intl.formatMessage({ id: 'digitalClinician' }) },
		{ value: CompanyRoles.DOCTOR, label: intl.formatMessage({ id: 'doctor' }) },
		{ value: CompanyRoles.VISITOR, label: intl.formatMessage({ id: 'visitor' }) },
	];

	useEffect(() => {
		const fetchMembers = async () => {
			let response = {};
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				filterType: MembersType.NON_AD_MEMBERS,
				...filterValues,
			};
			if (userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.ADMIN) {
				if (userRole === UserRoles.SUPER_ADMIN) {
					params.roles.push(CompanyRoles.SUPER_ADMIN);
				}

				response = await getMembers(params);
			} else {
				response = await getHealthSystemMembers({
					healthSystemId: userSession.healthSystem.id,
					...params,
				});
			}

			if (response.error) {
				setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			} else {
				setMembers(response.members);
				setTotalCount(response.totalCount);
			}
			setIsLoading(false);
		};

		fetchMembers();

		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [filterValues, pagination, userRole, userSession.healthSystem.id, intl]);

	const handleSearchChanges = values => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setFilterValues({
			searchValue: values.searchValue,
			roles: values.roles.map(item => item.value),
			healthSystems: values.healthSystems.map(item => item.value),
		});
	};

	const handleDeleteMember = async () => {
		setIsLoading(true);
		setIsBtnLoading(true);
		const response = await deleteMember(currentMemberObj.memberId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setMembers(prevMembers => prevMembers.filter(member => member.memberId !== currentMemberObj.memberId));
			setIsDeleteModalOpen(false);
		}
		setIsBtnLoading(false);
		setIsLoading(false);
		setCurrentMemberObj(null);
	};

	const updateMemberHealthSystems = healthSystems => {
		setMembers(prevMembers =>
			prevMembers.map(pm => {
				if (pm.memberId === currentMemberObj.memberId) {
					return { ...pm, healthSystems: healthSystems };
				}
				return pm;
			})
		);
	};

	const getRoles = user => {
		if (!user.roles) return 'N/A';
		return user.roles.map(role => (
			<div key={role.id} className='badge blue'>
				{getRoleValueById(getUserTypeBasedOnCompanyRole(role.id), companySettings)}

				{!shouldHideRemoveIcon(role.id, user.healthSystems.length > 1, user.roles) && (
					<i className='material-icons remove-role-btn' onClick={() => openDeleteRoleModal(role, user)}>
						close
					</i>
				)}
			</div>
		));
	};

	const shouldHideRemoveIcon = (roleId, hasMultipleHs, roles) =>
		userRole === UserRoles.SUPER_USER && (roleId === CompanyRoles.ADMIN || hasMultipleHs) && roles.length < 2;

	const isNurseOrDoctor = roles => {
		if (!roles && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole)) return false;
		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.VIRTUAL_SITTER,
			].includes(role.id)
		);
	};

	const showDeleteButton = (roles, memberId) => {
		if (roles && roles.length > 0) {
			if ([UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(userRole)) {
				return !roles?.some(role => [CompanyRoles.OWNER].includes(role.id));
			}
			if (userRole === UserRoles.SUPER_USER) {
				const memberHealthSystems = members.find(x => x.memberId === memberId);
				let canDelete = false;
				if (memberHealthSystems && memberHealthSystems.healthSystems) {
					canDelete =
						memberHealthSystems.healthSystems.length === 1 &&
						memberHealthSystems.healthSystems[0].id === userSession.healthSystem.id;
				}
				return (
					!roles?.some(role => [CompanyRoles.OWNER, CompanyRoles.ADMIN, CompanyRoles.SUPER_USER].includes(role.id)) && canDelete
				);
			}
		}
		return true;
	};

	const shouldShowAddRoleButton = (roles, memberId) => {
		const maxRolesLength = 6;
		if ([UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) && (!roles || roles.length >= maxRolesLength)) {
			return false;
		}

		if (userRole === UserRoles.SUPER_USER) {
			const memberHealthSystems = members.find(x => x.memberId === memberId);
			let canAddRoles = false;
			if (memberHealthSystems && memberHealthSystems.healthSystems) {
				canAddRoles =
					memberHealthSystems.healthSystems.length === 1 &&
					memberHealthSystems.healthSystems[0].id === userSession.healthSystem.id;
			}
			return (
				roles.some(role =>
					[
						CompanyRoles.DOCTOR,
						CompanyRoles.VIRTUAL_CARE_PROVIDER,
						CompanyRoles.DIGITAL_CLINICIAN,
						CompanyRoles.VIRTUAL_SITTER,
					].includes(role.id)
				) && canAddRoles
			);
		}

		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.ADMIN,
				CompanyRoles.VIRTUAL_SITTER,
				CompanyRoles.SUPER_USER,
			].includes(role.id)
		);
	};

	const getMemberDetails = (user, presence, presenceColor) => {
		const currentUser = getUserInfo().userId === user.userInfo.userId;
		return {
			id: user.memberId,
			profileBox: getProfileBox(decodeHtml(`${user.userInfo.firstName} ${user.userInfo.lastName}`), currentUser),
			email: user.userInfo.email,
			invitedBy: user.inviter
				? getProfileBox(
						decodeHtml(`${user.inviter.firstName} ${user.inviter.lastName}`),
						getUserInfo().userId === user.inviter.userId
				  )
				: 'N/A',
			dateInvited: user.dateInvited
				? moment.utc(user.dateInvited).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			dateAdded: user.dateAdded
				? moment.utc(user.dateAdded).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			...(userRole !== UserRoles.SUPER_ADMIN && {
				healthSystem:
					user.healthSystems.length > 0
						? user.healthSystems.map((hs, index) => (
								<span>
									{' '}
									{hs.name?.trimEnd()}
									{user.healthSystems.length !== index + 1 && ','}
								</span>
						  ))
						: 'N/A',
			}),
			status: <Badge text={presence} variant={presenceColor} />,
			...(userRole !== UserRoles.SUPER_ADMIN && {
				role: getRoles(user),
			}),
			edit: !currentUser && getEditButtons(user),
		};
	};

	const displayUsers = () => {
		if (members.length === 0) return [];
		return members.map(user => {
			let presence;
			let presenceColor;
			if (user.userInfo.presenceStatusTypeId === 1) {
				presence = translate('available');
				presenceColor = 'green';
			} else {
				presence = translate('unavailable');
				presenceColor = 'red';
			}
			return getMemberDetails(user, presence, presenceColor);
		});
	};

	const getProfileBox = (name, currentUser) => (
		<div className='div-container'>
			<span className={currentUser ? 'bold' : ''}>
				{name} {currentUser && `(${intl.formatMessage({ id: 'you' })})`}
			</span>
		</div>
	);

	const shouldShowPinGenerator = member =>
		[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) &&
		getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) &&
		isNurseOrDoctor(member.roles);

	const getEditButtons = member => {
		const is2FAEnabled =
			[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) &&
			companySettings?.twoFactorAuthenticator === AuthenticationOptions.EXTERNAL_AUTHENTICATOR;

		return (
			<div className='wrapped'>
				{is2FAEnabled && <TableTwoFactorAuthButton onClick={() => openResetTwoFaAuthenticationModal(member.userInfo?.email)} />}
				{shouldShowAddRoleButton(member.roles, member.memberId) && <TableAddButton onClick={() => openAddRoleModal(member)} />}
				{isNurseOrDoctor(member.roles) && <TableEditButton onClick={() => openEditMemberModal(member)} />}
				{shouldShowPinGenerator(member) && <TablePinButton onClick={() => setPinGenerateMember(member.userInfo?.id)} />}
				{showDeleteButton(member.roles, member.memberId) && <TableDeleteButton onClick={() => openDeleteMemberModal(member)} />}
			</div>
		);
	};

	const openEditMemberModal = member => {
		setCurrentMemberObj(member);
		setIsEditMemberModalOpen(true);
	};

	const openDeleteMemberModal = member => {
		setCurrentMemberObj(member);
		setIsDeleteModalOpen(true);
	};

	const openAddRoleModal = member => {
		setCurrentMemberObj(member);
		setIsAddRoleModalOpen(true);
	};

	const resetTwoFactorAuthentication = async () => {
		const email = resetTwoFaModalData?.email;
		const data = await resetTwoFactorAuth(email);
		if (data?.error) {
			setError(data?.error.message);
		}

		setIsTwoFaResetModalOpen(false);
		setResetTwoFaModalData(null);
	};

	const openResetTwoFaAuthenticationModal = email => {
		setIsTwoFaResetModalOpen(true);
		setResetTwoFaModalData({ email });
	};

	const createMemberRole = async (data, { resetForm }) => {
		setIsBtnLoading(true);
		setIsLoading(false);
		const params = {
			companyId,
			memberId: currentMemberObj.memberId,
			role: data.selectedRole?.value,
			teamId: data.healthSystem ? data.healthSystem.value : null,
		};
		const addRoleResponse = await addMemberRole(params);
		if (addRoleResponse.error) {
			setError(addRoleResponse.error.message);
		} else {
			setMembers(currentMembers => {
				const memberIndex = currentMembers.findIndex(member => member.memberId === currentMemberObj.memberId);
				if (memberIndex === -1) return currentMembers;
				const updatedMembers = [...currentMembers];
				updatedMembers[memberIndex] = {
					...updatedMembers[memberIndex],
					roles: [...updatedMembers[memberIndex].roles, { id: data.selectedRole.value, name: data.selectedRole.label.trim('') }],
					...(data.healthSystem && { healthSystems: data.healthSystem }),
				};

				return updatedMembers;
			});
			if (data.healthSystem) {
				updateMemberHealthSystems([{ id: data.healthSystem.value, name: data.healthSystem.label }]);
			}
			resetForm();
		}

		setIsAddRoleModalOpen(false);
		setIsLoading(false);
		setIsBtnLoading(false);
		setCurrentMemberObj(null);
	};

	const openDeleteRoleModal = (role, member) => {
		setCurrentMemberObj(member);
		setRoleToDelete(role);
	};

	const handleDeleteMemberRole = async () => {
		setIsBtnLoading(true);
		const params = {
			companyId,
			memberId: currentMemberObj.memberId,
			roleId: roleToDelete.id,
		};
		const deleteRoleResponse = await deleteMemberRole(params);
		if (deleteRoleResponse.error) {
			setError(deleteRoleResponse.error.message);
		} else {
			setMembers(currentMembers => {
				const memberIndex = currentMembers.findIndex(member => member.memberId === currentMemberObj.memberId);
				if (memberIndex === -1) return currentMembers;
				const updatedMembers = [...currentMembers];
				const updatedRoles = updatedMembers[memberIndex].roles.filter(role => role.id !== roleToDelete.id);
				updatedMembers[memberIndex] = {
					...updatedMembers[memberIndex],
					roles: updatedRoles,
					healthSystems:
						updatedRoles.length === 1 && updatedRoles[0].name === UserRoles.ADMIN
							? []
							: updatedMembers[memberIndex].healthSystems,
				};

				return updatedMembers;
			});
		}
		setIsBtnLoading(false);
		setIsLoading(false);
		setRoleToDelete(null);
		setCurrentMemberObj(null);
	};

	const selectInviteType = id => {
		props.toggleSendInvitationsModal(id);
		setIsInviteUsersDropDown(false);
	};

	const getFiltersInitialValues = () => ({
		searchValue: '',
		roles: [],
		healthSystems: [],
	});

	return (
		<div>
			<CustomTable
				isLoading={isLoading}
				headers={usersTableHeaders}
				rows={isLoading ? [] : displayUsers()}
				className='admin-table'>
				<div className='flex'>
					<Formik initialValues={getFiltersInitialValues()} onSubmit={handleSearchChanges}>
						{formikProps => {
							const { values, handleSubmit, handleBlur, setFieldValue } = formikProps;
							const handleChange = (name, value) => {
								setFieldValue(name, value);
								if (name === 'searchValue') {
									clearTimeout(delayTimerRef.current);
									const timer = setTimeout(() => {
										handleSubmit();
									}, 500);

									delayTimerRef.current = timer;
								} else {
									handleSubmit();
								}
							};

							return (
								<div className='members-filters flex-1'>
									<Input
										validationOptions={{}}
										type='text'
										placeholder={intl.formatMessage({ id: 'nameOrEmail' })}
										bottomSpace='0'
										variant='filled'
										name='searchValue'
										onChange={event => handleChange('searchValue', event.target.value)}
										maxLength={100}
										value={values.searchValue}
									/>
									{getUserRole() !== UserRoles.SUPER_ADMIN && (
										<>
											{getUserRole() !== UserRoles.SUPER_USER && (
												<MultiSelectCheckboxes
													name='healthSystems'
													selectedOptions={values.healthSystems}
													searchable
													reorderSelected
													options={allHealthSystems.map(item => ({ value: item.id, label: item.name }))}
													placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
													onChange={value => handleChange('healthSystems', value)}
													selectedItemsPlaceholder={intl.formatMessage({ id: 'healthSystems' })}
													onBlur={handleBlur}
													maxSelect={maxSelectItems}
													errorMessage={intl.formatMessage(
														{ id: 'cannotSelectNrMoreThanValue' },
														{ value: maxSelectItems, item: intl.formatMessage({ id: 'healthSystems' }) }
													)}
												/>
											)}
											<MultiSelectCheckboxes
												name='roles'
												selectedOptions={values.roles}
												searchable
												reorderSelected
												options={rolesOptions}
												onChange={value => handleChange('roles', value)}
												placeholder={intl.formatMessage({ id: 'selectRole' })}
												selectedItemsPlaceholder={intl.formatMessage({ id: 'roles' })}
												onBlur={handleBlur}
												maxSelect={maxSelectItems}
												errorMessage={intl.formatMessage(
													{ id: 'cannotSelectNrMoreThanValue' },
													{ value: maxSelectItems, item: intl.formatMessage({ id: 'roles' }) }
												)}
											/>
										</>
									)}
								</div>
							);
						}}
					</Formik>
					{UserRoles.ADMIN === userRole && (
						<div className='position-relative invite-users-wrapper'>
							<Button
								onClick={() => setIsInviteUsersDropDown(prevState => !prevState)}
								text={translate('inviteUsers')}
								imgIcon={`${healthCareCdnUrl}appointments/create-appointment.svg`}
							/>
							{isInviteUsersDropDown && (
								<div className='full-page-input-results'>
									{props.inviteType.map(type => (
										<div key={type.inviteTypeId} className='cursor-pointer' onClick={() => selectInviteType(type.inviteTypeId)}>
											<span>{type.name}</span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{userRole === UserRoles.SUPER_ADMIN && (
						<Button
							text={translate('addSuperAdmin')}
							horizAlign='end'
							onClick={() => selectInviteType(InviteTypes.SUPER_ADMIN)}
						/>
					)}
				</div>
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => {
						setIsLoading(true);
						setPagination({ pageSize, pageIndex });
					}}
				/>
			)}
			{getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) && (
				<PinGenerate memberId={pinGenerateMember} onModalClose={() => setPinGenerateMember(null)} />
			)}
			<Modal
				display={isDeleteModalOpen}
				position='center'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={() => handleDeleteMember()}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setIsDeleteModalOpen(false);
				}}>
				<form>
					<h3>{translate('deleteMember')}</h3>
					<p>{translate(getUserRole() !== UserRoles.SUPER_ADMIN ? 'areYouSureDeleteMember' : 'areYouSureDeleteThisMember')}</p>
				</form>
			</Modal>
			{currentMemberObj !== null && (
				<ManageHealthSystems
					member={currentMemberObj}
					updateMemberHealthSystems={updateMemberHealthSystems}
					healthSystems={props.healthSystems}
					modalSelector='editMemberModal'
					display={isEditMemberModalOpen}
					className='manage-hss'
					position='right'
					onModalClose={() => {
						setCurrentMemberObj(null);
						setIsEditMemberModalOpen(false);
					}}
				/>
			)}
			<AddMemberRole
				isAddRoleModalOpen={isAddRoleModalOpen}
				onAddMemberRole={createMemberRole}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setIsAddRoleModalOpen(false);
				}}
				healthSystems={props.healthSystems}
				isBtnLoading={isBtnLoading}
				currentMemberObj={currentMemberObj}
			/>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				display={roleToDelete}
				position='center'
				onModalSubmit={handleDeleteMemberRole}
				onModalClose={() => {
					setCurrentMemberObj(null);
					setRoleToDelete(null);
				}}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={intl.formatMessage({ id: 'yes' })}
				primaryButtonLoading={isBtnLoading}>
				<Form height={200} className='modal-form-wrapper'>
					<h3>{translate('deleteRole')}</h3>
					<p>{translate('areYouSureDeleteRole')}</p>
				</Form>
			</Modal>
			<Modal
				modalSelector='TwoFaResetModal'
				display={isTwoFaResetModalOpen}
				position='center'
				onModalSubmit={resetTwoFactorAuthentication}
				onModalClose={() => setIsTwoFaResetModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureTwoFaReset')}</p>
				</form>
			</Modal>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</div>
	);
};

export default UsersList;
